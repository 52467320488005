@font-face {
    font-family: 'Brick';
    src: url('BrickRegular.eot');
    src: url('BrickRegular.eot?#iefix') format('embedded-opentype'),
        url('BrickRegular.woff2') format('woff2'),
        url('BrickRegular.woff') format('woff'),
        url('BrickRegular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

