@import "./fonts.css";

/* * {
  cursor:
    url("../assets/images/cursor.png") 32 32,
    auto;
} */

html {
  width: 100%;
  font-size: 1vw;
  scroll-padding-top: 10vh !important;
  @apply overflow-x-hidden;
}

@media (max-width: 768px) {
  html {
    font-size: 5vw;
  }
}

@media (min-width: 1024px) {
  html {
    margin-top: 0;
  }
}
