@tailwind utilities;
@layer responsive {
  .text-shadow {
    text-shadow: 0px 0px 0.25vh #ba00ff;
  }
  .text-shadow-md {
    text-shadow: 4px 4px 8px #ba00ff;
  }
  .text-shadow-lg {
    text-shadow: 15px 15px 30px #ba00ff;
  }
  .text-shadow-none {
    text-shadow: none;
  }
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  @apply rounded-full;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}

/* Handle */
::-webkit-scrollbar-thumb {
  @apply bg-primary;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

input:-webkit-autofill {
  transition:
    background-color 600000s 0s,
    color 600000s 0s;
}

input[data-autocompleted] {
  background-color: transparent !important;
}

canvas {
  width: 100vw !important;
  height: 100vh !important;
  background-color: black;
}

.heading-2 {
  @apply font-secondary uppercase text-center text-[2em] md:text-[3em] font-bold leading-none;
}

.heading-3 {
  @apply text-white uppercase text-center text-[2em] md:text-[5em] font-bold tracking-widest leading-none;
}

.bounce {
  animation: bounce 4s infinite;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateX(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

.no-scroll {
  @apply fixed top-0 left-0 h-screen w-full overflow-hidden;
}

.position-center {
  @apply absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2;
}

/* SHOPIFY HIDE OTHER IFRAME */
.shopify-buy-frame iframe {
  display: none !important;
}

.shopify-buy-frame iframe:nth-child(1) {
  display: block !important;
}

.btn-primary {
  @apply py-[1vh] px-[4vh] m-auto border-[.2vh] flex border-white uppercase rounded-full text-center whitespace-nowrap text-[1em] hover:bg-white hover:text-black;
  -webkit-text-stroke-width: 0.05vh;
  -webkit-text-stroke-color: #ba00ff;
}

.link {
  @apply mx-0 md:mx-[1em] first:ml-0 uppercase cursor-pointer text-[2em];
}

#video iframe,
#video .video-img {
  width: 100%;
  max-width: 350px;
  height: 190px;
  margin: auto;
}

@media (min-width: 768px) {
  #video iframe,
  #video .video-img {
    height: 410px;
    max-width: 700px;
  }
}

.bit-top-track-button,
.bit-nav-bar-container,
.bit-play-my-city-button {
  display: none !important;
}
